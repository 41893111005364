.tmds-notification {
  &_dot {
    @include inBlind;
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--C-red-400);
  }
  &_new {
    @include inBlind;
    display: inline-block;
    @include set-bg(NewBadge);
    width: 16px;
    height: 16px;
  }
  &_counter {
    display: inline-block;
    overflow: hidden;
    height: 16px;
    padding: 0 5px;
    border-radius: 20px;
    color: var(--C-white);
    line-height: 1.6rem;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-11);
  }
}
