.toast {
  &_wrap {
    @extend %flexbox-v;
    @include overflowHide();
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: var(--Z-layer);
    text-align: center;
    transform: translateX(-50%);
    margin: initial;
    box-sizing: border-box;
    touch-action: none;
    font-size: var(--font-size-16);


    &.snackbar {
      width: calc(100% - 40px);
      max-width: 400px;
      max-height: 80%;
      background-color: var(--C-gray-900);
      border-radius: 8px;
    }
    &.floating {
      padding: 12px 16px;
      box-shadow: 0 0 20px var(--C-wb-transparent-300);
      background-color: var(--C-white);
      border-radius: 56px;
      color: var(--C-white);
    }
  }
}

.snackbar {
  &_cont {
    @extend %flexbox-stretch-v;
    @include overflowHide();
    position: relative;
    -webkit-overflow-scrolling: touch;
    text-align: left;
    &_toast {
      width: 65px;
      color: var(--C-primary-400);
      font-weight: var(--font-weight-medium);
    }
  }
  &_subject {
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    color: var(--C-white);
  }
}

.floating {
  &_icon {
    width: 20px;
    height: 20px;
    margin: 2px 4px 0 0;
  }

  &_btn {
    font-weight: var(--font-weight-medium);
    line-height: 24px;
  }
}