@charset "UTF-8";

// animatiaon
%anime-default {
  transition: all 0.15s ease-out;
  will-change: auto;
}
%anime-fade {
  transition: all 0.3s ease-out;
  will-change: opacify;
}
%card-touch-in {
  animation: card-touch-in ease-out 0.12s forwards;
}
%card-touch-out {
  animation: card-touch-out ease-out 0.12s forwards;
}
// flex default
%flexbox {
  @include flex();
}

%flexbox-stretch {
  @include flex();
  @include flex-grow(1);
}

%flexbox-stretch-noshrink {
  @include flex();
  @include flex-grow(1, 0);
}

%flexbox-v {
  @include flex();
  @include flex-direction(column);
}

%flexbox-stretch-v {
  @include flex();
  @include flex-grow(1);
  @include flex-direction(column);
}

%flexbox-stretch-noshrink-v {
  @include flex();
  @include flex-grow(1, 0);
  @include flex-direction(column);
}

%flexbox-fix {
  @include flex();
  @include flex-grow(0, 0);
}

%flexbox-fix-v {
  @include flex();
  @include flex-grow(0, 0);
  @include flex-direction(column);
}

%flex-center {
  @include flex();
  @include flex-justify(center);
}
%flex-between {
  @include flex();
  @include flex-justify(space-between);
}
%flex-around {
  @include flex();
  @include flex-justify(space-around);
}
%btn-disabled {
  &:disabled,
  &.disabled {
    @include opacity(0.3);
    cursor: default;
  }
}

// cont margin box
%cont-margin-box {
  margin-right: -(var(--G-contents-gap));
  margin-left: -(var(--G-contents-gap));
}
%cont-margin-padding-box {
  margin-right: -(var(--G-contents-gap));
  margin-left: -(var(--G-contents-gap));
  padding-right: var(--G-contents-gap);
  padding-left: var(--G-contents-gap);
}

// disabled
%disabled {
  opacity: 0.4;
}

// iphone x
%iphone-padding-bottom {
  &:after {
    display: block;
    font-size: 0;
    line-height: 0;
    padding-top: 0;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    content: '';
  }
}
