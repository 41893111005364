@charset "UTF-8";

.container {
  width: 100%;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;

  // 회색 배경을 가지고 있는 화면
  &.main {
    background: var(--C-gray-750);
  }
  &.sub {
    background: var(--gray-50);
    min-height: 100vh;
  }
  &.sub2 {
    background: var(--gray-100);
    min-height: 100vh;
  }
}

.header {
  @include position(0, 0, null, 0, var(--Z-header), sticky);
  min-height: var(--G-header-heights);
  background-color: var(--C-white);

  .main & {
    position: absolute;
    min-height: initial;
    background: none;
  }
  &.fixed {
    position: fixed;
    border-bottom: 1px solid var(--C-gray-200);
  }
  &.transparent {
    position: fixed;
    background-color: rgba(255, 255, 255, 0);
  }
  &.sticky {
    &::after {
      @include position(null, 0, 0, 0);
      content: '';
      border-bottom: 1px solid var(--C-gray-200);
    }
  }
  &_title {
    @include ellipsis(block);
    height: var(--G-header-heights);
    padding: 0 56px;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-18);
    line-height: 5.6rem;
    text-align: center;
  }
  &_back {
    @include set-overlay;
    overflow: hidden;
    position: absolute;
    top: 8px;
    left: 8px;
    width: 40px;
    height: 40px;
    background: url("../../assets/images/ico-40-back.svg") no-repeat 0 0 / 100% 100%;
    color: var(--C-tr);
    font-size: 1px;
    text-indent: -100px;
    &.low_spec {
      top: 6px;
      left: 16px;
      width: 28px;
      height: 28px;
      background-image: url("../../assets/images/ico_arrow_left.svg");
    }
    &.reversal {
      background-image: url("../../assets/images/ico_40_back_white-2.svg");
    }
    &.round {
      width: 52px;
      height: 56px;
      background-image: url("../../assets/images/ico_prev.svg");

      &::after {
        right: initial;
        bottom: initial;
      }
      &:active {
        &::after {
          width: 44px;
          height: 44px;
          transform: translate(4px, 6px);
          background-color: var(--C-wb-transparent-100);
          border-radius: 50%;
        }
      }
    }
  }
  &_share {
    @include inBlind;
    @include position(14px, 56px);
    @include set-bg(ico_share);
    position: absolute !important;
    width: 28px;
    height: 28px;
  }
  &_otp {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 100;
    height: 56px;
    padding: 0 25px;
    color: var(--C-gray-900);
    line-height: 5.6rem;

    &_tooltip {
      @include position(42px, 20px);
      opacity: 0;
      cursor: default;
      transition: all .4s;

      &.on{
        z-index: 1;
        opacity: 1;
        transform: translate(0, 0);
      }
      &_cont {
        position: relative;
        padding: 10px 14px;
        border-radius: 4px;
        background: var(--C-primary-600);
        font-size: var(--font-size-14);
        line-height: 2rem;
        text-align: center;
        color: var(--C-white);
        white-space: nowrap;

        &:after {
          content: "";
          position: absolute;
          right: 14px;
          bottom: 100%;
          border-width: 0 6px 6px;
          border-style: solid;
          border-color: var(--C-tr) var(--C-tr) var(--C-primary-600);
        }
      }
    }
  }
  // 닫기 버튼
  &_close {
    overflow: hidden;
    position: absolute !important;
    top: 0;
    right: 12px;
    bottom: 0;
    width: 28px;
    height: 28px;
    margin: auto 0;
    mask: url("../../assets/images/ico_exit.svg") no-repeat 0 0 / 100% 100%;
    background-color: #171819;
    font-size: 1px;
    text-indent: -100px;
  }
  // 카테고리 탭/설명
  &_nav {
    @include flex;
    position: relative;
    padding: 0 20px;
    background: var(--C-white);

    &::after {
      @include position(56px, 0, null, 0);
      content: '';
      border-bottom: 1px solid var(--C-gray-200);
    }
    &_cell {
      flex: 1;
    }
    &_item {
      display: block;
      position: relative;
      height: 56px;
      padding: 16px 0;
      box-sizing: border-box;
      color: var(--C-gray-900);
      text-align: center !important;
      line-height: 2.4rem;
      white-space: nowrap !important;

      &.active {
        color: var(--C-primary-500);
        font-weight: var(--font-weight-medium);

        &::before {
          @include position(null, 0, -1px, 0, 100);
          content: '';
          border-bottom: 3px solid var(--C-primary-500);
        }
      }
    }
    &_sub {
      overflow: hidden;
      overflow-x: auto;
      padding: 10px 16px;
      box-sizing: border-box;
      background: var(--C-white);
      white-space: nowrap;

      &::-webkit-scrollbar {
        display: none;
      }
      &_cell {
        display: inline-block;
        margin: 0 4px;
      }
      &_item {
        width: auto !important;
        height: 34px;
        padding: 0 12px;
        border: 1px solid var(--C-gray-300);
        border-radius: 40px;
        font-size: var(--font-size-14);
        white-space: nowrap !important;

        &.active {
          border: 0;
          background: var(--C-primary-500);
          color: var(--C-white);
          font-weight: var(--font-weight-medium);
        }
        &.is_new {
          &::before {
            @include position(-4px, -4px);
            @include set-bg(NewBadge);
            content: '';
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  &_filter {
    @include flex;
    @include flex-justify(space-between);
    @include flex-align(center);
    min-height: 64px;
    padding: 8px 20px 16px;
    box-sizing: border-box;

    &_status {
      @include flex;
      @include flex-align(center);
      line-height: 2.8rem;
      font-size: var(--font-size-20);
      font-weight: var(--font-weight-medium);
    }
  }
}

.contents {
  position: relative;
  transition: padding-bottom .2s ease;
}
