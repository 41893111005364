.ly_tooltip {
  display: none;
  position: absolute;
  z-index: 100;
  left: 20px;
  right: 20px;
  max-width: 400px;
  margin: 8px auto 0;
  padding: 16px;
  border: 1px solid var(--C-gray-400);
  border-radius: 4px;
  background: var(--C-white);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.14);

  &_tit {
    margin-bottom: 4px;
    padding-right: 20px;
    color: var(--C-gray-700);
    line-height: 2rem;
    font-size: var(--font-size-14);
    font-weight: var(--font-weight-medium);
  }
  &_cont {
    padding-right: 26px;
  }
  &_desc {
    color: var(--C-gray-500);
    line-height: 1.8rem;
    font-size: var(--font-size-12);

    &_item {
      padding-left: 14px;
      text-indent: -14px;

      &.no_bullet {
        padding: 0;
        text-indent: 0;
      }
      white-space: pre-wrap;
    }
  }
  &_additional {
    margin-top: 18px;
    color: var(--C-gray-500);
    line-height: 1.8rem;
    font-size: var(--font-size-12);
  }
  &_close {
    position: absolute !important;
    top: 16px;
    right: 16px;
    z-index: 20;
    width: 20px;
    height: 20px;
    text-indent: -100px;
    font-size: 0;

    &::before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      mask: url("../../assets/images/ico_exit.svg") no-repeat 0 0 / 100% 100%;
      background-color: var(--C-gray-700);
    }
  }
}
