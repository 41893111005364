@charset "UTF-8";

// 긴급 출동
.emergency_call {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 56px);
  padding: 0 20px;
  box-sizing: border-box;
  text-align: center;

  &_body {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &_footer {
    padding: 20px 0 16px;
  }
  &_insurer {
    margin-top: 8px;
    line-height: 3.6rem;
    font-size: var(--font-size-24);
    font-weight: var(--font-weight-medium);
  }
  &_modify {
    height: 40px;
    margin-top: 24px;
    padding: 0 16px;
    border: 1px solid var(--C-gray-400);
    border-radius: 8px;
    text-align: center !important;
  }
  &_tit {
    margin-top: 16px;
    line-height: 2.4rem;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
  }
  &_desc {
    margin-top: 16px;
    padding: 0 20px;
    color: var(--C-gray-500);
    line-height: 2.4rem;
    word-break: keep-all;
  }
  &_img {
    max-width: 400px;
    margin-top: 24px;
  }
  &_direct {
    overflow: hidden;
    height: 52px;
    margin-top: 8px;
    border-radius: 8px;
    color: var(--C-gray-500);
    text-align: center !important;
  }
}

// 보험사 긴급출동 연락처
.insurer_contact {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 20px 20px 104px;

  &_item {
    @include set-overlay;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 12px 8px 8px;
    border: 1px solid var(--C-gray-300);
    border-radius: 8px;
    color: initial;
    text-align: center !important;
  }
  &_name {
    margin-top: 4px;
    line-height: 1.8rem;
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-medium);
  }
  &_number {
    color: var(--C-gray-500);
    line-height: 1.6rem;
    font-size: var(--font-size-11);
  }
  &_call {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.8rem;
    margin-top: 8px;
    border-radius: 4px;
    background: var(--C-gray-200);
    line-height: 2.8rem;
    font-size: var(--font-size-11);

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      margin-right: 4px;
      mask: url("../../assets/images/ico_phone.svg") no-repeat 0 0 / 100% 100%;
      background-color: var(--C-gray-700);
    }
  }
}

// 공통 필수 약관 동의
.terms {
  position: relative;
  height: 100vh;
  &_content {
    margin: 20px;
  }

  &_title {
    line-height: 2.8rem;
    text-align: left;
    font-size: var(--font-size-20);
    font-weight: var(--font-weight-medium);
  }

  &_checkbox {
    &_all {
      display: block;
      position: relative;
      padding: 8px 0 8px 58px;
      border: 1px solid var(--C-gray-200);
      border-radius: 8px;
      margin: 13px 0 18px;
      width: 100%;
      text-align: left;

      &.checked {
        border: 1px solid var(--C-primary-500);
      }

      &:active {
        background-color: var(--C-gray-100);
      }

      &_label {
        display: block;
        font-size: var(--font-size-16);
        font-weight: var(--font-weight-medium);
        color: var(--C-gray-900);
        line-height: 3.8rem;

        &::before {
          content: "";
          width: 20px;
          height: 20px;
          @include position(calc(50% - 10px), 0, null, 16px);
          @include set-bg(checkbox_s_off);
        }

        &.checked {
          color: var(--C-primary-500);
          &::before {
            @include set-bg(checkbox_s_on);
          }
        }
      }
    }

    &_sub_terms {
      margin-left: 32px;
      @media all and ( orientation: landscape ) {
        margin-bottom: 40px;
      }
    }

    &_wrap {
      @media all and ( orientation: landscape ) {
        &:last-of-type {
          margin-bottom: 40px;
        }
      }
    }

    &_single_terms {
      @media all and ( orientation: landscape ) {
        margin-top: 16px;
      }
    }

    &_item {
      position: relative;
      padding-left: 32px;
      margin-bottom: 8px;

      @media all and ( orientation: landscape ) {
        margin-bottom: 4px;
      }

      &.sub {
        padding-left: 22px;
        @media all and ( orientation: landscape ) {
          display: inline-block;
        }
        &_opt {
          padding-left: 22px;
          display: inline-block;
          white-space: nowrap;
        }
      }

      &_label {
        display: block;
        color: var(--C-gray-500);
        font-size: var(--font-size-14);
        line-height: 2.4rem;
        word-break: break-all;
        padding-right: 12px;
        text-align: left;

        &::before {
          @include position(calc(50% - 10px), null, null, 4px);
          content: '';
          width: 20px;
          height: 20px;
          @include set-bg(checkicon_s_off);
        }

        &.checked {
          &::before {
            @include set-bg(checkicon_s_on);
          }
        }

        &.sub {
          &::before {
            @include position(calc(50% - 8px), null, null, 0);
            content: '';
            width: 16px;
            height: 16px;
            @include set-bg(checkicon_s_off);
          }
          &.checked {
            &::before {
              @include set-bg(checkicon_s_on);
            }
          }
        }
      }

      &_link {
        @include position(calc(50% - 8px), 0);
        @include inBlind;
        width: 12px;
        height: 16px;
        @include set-bg(icon_arrow_right_gray_12);
      }
    }
  }
}


// 메인 > 공통 약관 동의 후 내 차 등록 유도
.vehicle {
  &_register {
    &_cont {
      &_img {
        display: flex;
        justify-content: center;
        margin-top: 16px;
        img {
          margin-right: 8px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
      &_desc {
        margin-top: 32px;
        text-align: center;
        color: var(--C-gray-700);
      }
    }
    &_btn {
      font-size: var(--font-size-18) !important;
      font-weight: var(--font-weight-medium) !important;
    }
    &_cancel {
      margin-right: 8px;
      font-size: var(--font-size-18) !important;
    }
  }
}
