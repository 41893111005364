.tmds-tab {
  overflow: hidden;
  position: relative;

  &_tray {
    @include flex;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &_panel {
  }
  &_button {
    color: var(--C-gray-900);
    text-align: center !important;
    white-space: nowrap !important;

    &.active {
      font-weight: var(--font-weight-medium);
    }
  }
  // Default
  // Scrollable
  &.default &,
  &.scrollable & {
    &_tray {
      padding: 0 20px;

      &::after {
        @include position(null, 0, 0, 0, 0);
        content: '';
        border-bottom: 1px solid var(--C-gray-200);
      }
    }
    &_panel {
      flex: 1;
    }
    &_button {
      height: 56px;

      &.active {
        color: var(--C-primary-500);

        &::before {
          @include position(null, 0, 0, 0, 1);
          content: '';
          border-bottom: 3px solid var(--C-primary-500);
        }
      }
    }
  }
  // Scrollable
  &.scrollable & {
    &_tray {
      overflow-x: auto;
    }
    &_panel {
      flex: none;
    }
    &_button {
      padding: 0 16px;
    }
  }
  // Capsule
  &.capsule & {
    &_tray {
      overflow-x: auto;
      padding: 10px 16px;
    }
    &_panel {
      flex: none;
      margin: 0 4px;
    }
    &_button {
      height: 40px;
      padding: 0 16px;
      border: 1px solid var(--C-gray-300);
      border-radius: 40px;
      font-size: var(--font-size-14);

      // 기본형 stroke
      // 스몰형 stroke
      &.stroke,
      &.stroke-small {
        &.active {
          border-color: var(--C-primary-500);
          color: var(--C-primary-500);
        }
      }
      // 기본형 solid
      // 스몰형 solid
      &.solid,
      &.solid-small {
        &.active {
          border: 0;
          background: var(--C-primary-500);
          color: var(--C-white);
        }
      }
      &.stroke-small,
      &.solid-small {
        height: 34px;
        padding: 0 12px;
      }
    }
  }
}
