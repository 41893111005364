.component {
  &_accordion {
    &_title {
      @include ellipsis;
      position: relative;
      padding: 20px 48px 20px 20px;
      text-align: left;

      &::before {
        @include position(50%, 20px);
        @include set-bg(ico_16_down);
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        transform: translateY(-50%);
      }
      &.active {
        &::before {
          background-image: url("../../assets/images/ico_16_up.svg");
        }
      }
    }
    &_content {
      white-space: pre-wrap;
    }
  }
}
