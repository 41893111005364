.floating{
  &_wrap {
    @include position(null, 0, 0, 0, 200, fixed);
    padding: 20px;

    &::before {
      @include position(0, 0, null, 0);
      content: '';
      height: 20px;
      background: linear-gradient(0, var(--C-white) 10%, rgba(255, 255, 255, 0) 100%);
    }
    &::after {
      @include position(20px, 0, 0, 0);
      content: '';
      background: var(--C-white);
    }
  }
  &_button {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 52px;
    border-radius: 8px;
    color: var(--C-white);
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
    text-align: center !important;

    &.primary {
      background-color: var(--C-primary-500);
      &:disabled {
        background-color: var(--gray-100);
        color: var(--C-gray-300);
      }
    }
    &.driving_analysis {
      background-color: var(--C-primary-500);
      font-weight: var(--font-weight-normal);
    }
    &.add_vehicle {
      height: 64px;
      padding: 14px 0;
      color: var(--C-primary-500);
      line-height: 2.4rem;
      font-size: var(--font-size-18);
      font-weight: var(--font-weight-medium);

      &::before {
        @include set-bg(ico_pencil);
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 8px;
        vertical-align: top;
      }
    }
    &.terms {
      height: 52px;
      background-color: var(--C-primary-500);
      &.clickable-disabled {
        background-color: var(--C-gray-100);
        color: var(--C-gray-300);
      }
    }
    &.red {
      background-color: var(--red-500);
    }
  }
}
