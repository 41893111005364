@charset "UTF-8";

.main {
  &_skeleton {
    padding: 22px;
  }
  // 메인 상단 영역
  &_header {
    position: relative;
    height: 224px;
    box-sizing: border-box;
    padding: 40px 16px 0;

    // 차량원부가 등록되지 않은 경우
    &.expand {
      height: 288px;
    }
    &_not_allowed {
      @include position(50%, null, null, 50%);
      transform: translate(-50%, -50%);
      text-align: center;
      color: var(--gray-200);
      font-size: var(--font-size-14);

      &_btn {
        overflow: hidden;
        width: 186px;
        height: 52px;
        margin-top: 24px;
        border-radius: 8px;
        text-align: center !important;
        background: var(--tmobi-blue-400);
        color: var(--wb-white);
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-18);
      }
      p {
        margin-top: 12px;
        white-space: nowrap;
      }
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
  // 운전점수 영역
  &_score {
    &_title {
      color: var(--gray-300);
      font-size: var(--font-size-12);
      line-height: 1.8rem;
    }
    &_wrapper {
      position: relative;
      box-sizing: border-box;
      min-height: 52px;
      min-width: 72px;
    }
    &_point {
      display: flex;
      align-items: center;
      color: var(--text-basic-inverse);
      font-size: 4.0rem;
      font-weight: var(--font-weight-bold);
      vertical-align: top;
      white-space: nowrap;
    }
    &_analysis {
      display: inline-block;
      position: relative;
      z-index: 30;

      &_link {
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        min-width: 80px;
        height: 36px;
        margin-top: 8px;
        border-radius: 4px;
        background: #646C7C;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-14);
        color: var(--text-basic-inverse);
      }
      &_dot {
        @include position(6px, 6px);
      }
      &_tooltip {
        @include position(calc(100% + 2px), null, null, 0);
        height: 30px;
        padding: 0 10px;
        border-radius: 8px;
        background: var(--tmobi-blue-400);
        color: var(--text-button-primary);
        line-height: 3rem;
        font-size: var(--font-size-12);
        white-space: nowrap;

        &::before {
          @include position(-10px, null, null, 24px);
          @include set-bg(ico_bubble);
          content: '';
          width: 12px;
          height: 10px;
        }
      }
    }
    &_rolling {
      overflow: hidden;
      height: 52px;
      min-width: 21px;
    }
    &_num {
      position: relative;
      transition: top 2s ease-out;
      line-height: 4.2rem;
    }
    &_unit {
      height: 36px;
      font-size: var(--font-size-20);
      line-height: 4.9rem;
    }
  }
  // 내차정보 영역
  &_vehicle {
    &_setting {
      display: inline-flex;
      gap: 4px;
      align-items: center;
      position: absolute !important;
      z-index: 20;
      top: 40px;
      right: 16px;
      color: var(--gray-100);
      line-height: 1.8rem;
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-medium);
      white-space: nowrap;
    }
    &_detail {
      position: absolute !important;
      z-index: 20;
      top: 58px;
      right: 16px;
      width: 200px;
      height: 120px;

      // 사파리 브라우저에서 이미지가 에러일 경우 대체 이미지가 화면에 랜더링되지 않는 이슈
      // 상위 엘리먼트가 absolute이고 넓이값이 있으면 이미지가 미표출 된다.
      .default {
        display: block;
      }
      &_ico {
        @include position(12px, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background-color: var(--C-gray-650);
      }
      &_text {
        @include position(25px, 0, null, 0);
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--gray-100);
        line-height: 1.8rem;
        font-size: var(--font-size-12);

        &::before {
          @include set-bg(sign_add_1);
          content: '';
          width: 32px;
          height: 32px;
          margin-bottom: 4px;
        }
      }
    }
    &_warning {
      @include position(6px, null, null, 50%);
      position: absolute !important;
      transform: translateX(-50%);
      font-size: 0;

      &::before {
        @include position(50%, null, null, 50%);
        @include set-bg(sign_warning_2);
        display: block;
        content: '';
        transform: translate(-50%, -50%);
        width: 16px;
        height: 16px;
      }
      &_msg {
        @include position(50%, null, null, calc(100% - 5px));
        transform: translateY(-50%);
        padding: 5px;
        background: #FDE8E4;
        color: var(--red-500);
        line-height: 1rem;
        font-weight: var(--font-weight-medium);
        font-size: 1.0rem;
        white-space: nowrap;

        &::before {
          @include position(50%, null, null, -10px);
          transform: translateY(-50%);
          display: block;
          content: '';
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 7px solid var(--red-200);
          border-bottom: 5px solid transparent;
          border-left: 5px solid transparent;
        }
      }
    }
    &_desc {
      position: absolute;
      right: 0;
      left: 0;
      bottom: 24px;
      text-align: center;
      font-size: var(--font-size-14);
      color: var(--C-gray-200);

      &.error {
        bottom: 22px;
      }
      &_btn {
        overflow: hidden;
        display: flex;
        align-items: center;
        gap: 0 4px;
        height: 40px;
        margin: 8px auto 0;
        padding: 0 19px;
        border-radius: 4px;
        background: var(--C-primary-400);
        color: var(--C-white);
        font-weight: var(--font-weight-medium);
        font-size: inherit;
      }
    }
  }
  // 메인 메뉴
  &_menu {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    display: flex;
    padding: 0 16px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
    &_item {
      display: inline-flex;
      align-items: center;
      flex-basis: 76px;
      position: relative;
      z-index: 20;
      height: 30px;
      margin-left: 6px;
      padding: 0 12px 0 10px;
      border-radius: 16px;
      box-sizing: border-box;
      background-color: var(--gray-700);

      &:first-of-type {
        margin-left: 0;
      }
      &_btn {
        display: flex;
        align-items: center;
        height: 100%;
      }
      &_label {
        display: inline-flex;
        align-items: flex-start;
        margin-left: 4px;
        color: var(--gray-200);
        font-size: var(--font-size-12);
        white-space: nowrap;
      }
      &_dot {
        margin-left: 2px;
      }
    }
  }

  // 메인 콘텐츠 영역
  &_content {
    min-height: calc(100vh - 288px);
    padding: 4px 0 120px;
    box-sizing: border-box;
    background: var(--gray-100);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
  }
  // 혜택 CTA
  &_cta {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 0;

    &_icon {
      width: 40px;
      height: 40px;
      padding: 0 8px 0 14px;
    }
    &_cont {
      word-break: keep-all;
      line-height: 2.4rem;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-18);
    }
    &_arrow {
      @include position(50%, 16px);
      transform: translateY(-50%);
    }
  }
  // 약관미동의 운전점수 샘플
  &_sample_score {
    padding: 0 20px 20px;

    &_graph {
      max-width: 240px;
      padding: 0 22px 24px;
    }
  }
  // 스와이프 배너
  &_banner {
    margin: 0 16px;
    &:first-child {
      padding-top: 20px;
    }

    @media all and (orientation: landscape) {
      display: none;
    }
    &_item {
      position: relative;
      text-align: center !important;

      &_img {
        width: 100%;
        max-width: 320px;
      }
    }
  }
  // 운전꿀팁
  &_tip {
    margin-top: 12px;
    padding-bottom: 20px;

    &_item {
      display: flex;
      align-items: center;
      padding: 12px 20px;
    }
    &_img {
      width: 80px;
      height: 60px;
      margin-right: 16px;
      border-radius: 4px;
    }
    &_tit {
      @include multiline-ellipsis(2);
      flex: 1;
      font-size: 1.5rem;
      line-height: 2.4rem;
    }
  }
  // 긴급호출
  &_emergency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    width: calc(100% - 32px);
    height: 72px;
    margin: auto 16px;
    padding: 0 16px;
    border-radius: 12px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.14);
    background: var(--C-white);

    &_label {
      display: flex;
      align-items: center;
      color: var(--C-gray-500);

      &::before {
        @include set-bg(img_carcare_item_siren);
        content: '';
        width: 32px;
        height: 32px;
        margin-right: 4px;
      }
    }
    &_insurer {
      display: flex;
      align-items: center;
      line-height: 3.2rem;
      font-weight: var(--font-weight-medium);
    }
    &_logo {
      margin-left: 8px;
    }
    &_register {
      padding: 0 12px;
      border-radius: 4px;
      background: var(--C-primary-500);
      color: var(--C-white);
      line-height: 3.6rem;
      font-size: var(--font-size-14);
    }
    &_other{
      display: inline-block;
      height: 36px;
      padding: 0 12px;
      border-radius: 4px;
      background: var(--C-gray-200);
      line-height: 3.6rem;
      font-size: var(--font-size-14);
    }
    &_error {
      color: var(--C-gray-400);
      line-height: 2.6rem;
    }
  }
  // 플로팅 CTA
  &_floating {
    @include position(calc(100% - 128px), 50%, 64px, 50%, var(--Z-full_popup), fixed);
    display: flex;
    width: 136px;
    height: 44px;
    translate: -50%;
    border-radius: 100px;
    border: solid 1px var(--tmobi-blue-200);
    background: var(--tmobi-blue-100);

    &.bottom-changed {
      top: unset;
      bottom: 16px;
    }

    &_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      font-size: var(--font-size-14);
      font-weight: var(--font-weight-medium);
      color: var(--tmobi-blue-600);

      &_ico {
        width: 20px;
        height: 20px;
      }
    }

    @media all and ( orientation: landscape ) {
      display: none;
    }
  }
  // 인기 혜택
  &_benefit_popular {
    @include position(calc(100% - 64px), 0, 0, 0, var(--Z-full_popup), fixed);
    border-radius: 12px 12px 0 0;
    background: var(--C-gray-100);

    &_link {
      @include flex;
      @include flex-align(center);
      overflow: hidden;
      height: 64px;
      padding: 0 12px;
      border-radius: 12px 12px 0 0;
      background: var(--tmobi-blue-400);
      color: var(--wb-white);
      font-size: var(--font-size-18);
      font-weight: var(--font-weight-medium);

      &::after {
        @include position(0, 20px, 0);
        @include set-bg(ico_arrow_up_bold);
        content: '';
        width: 24px;
        height: 24px;
        margin: auto;
      }
    }
    &_ico {
      width: 40px;
      height: 40px;
      margin-right: 4px !important;
    }
    &_tooltip {
      @include position(-14px, 16px);
      display: flex;
      align-items: center;
      gap: 4px;
      height: 26px;
      padding: 0 5px 0 10px;
      border: 1px solid var(--C-tmobi-purple-500);
      border-radius: 16px;
      background: var(--C-white);
      color: var(--C-tmobi-purple-500);
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-medium);
    }
  }
  // 차란차 콘텐츠, 최근 본 매물, 인기 매물
  &_charancha {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 8px 14px 40px;

    &::-webkit-scrollbar {
      display: none;
    }
    // 자동차 상품 예외 처리
    &.car {
      padding-top: 12px;
      padding-bottom: 20px;
    }
    &_item {
      width: 136px;
      margin: 0 6px;

      &_thumb {
        overflow: hidden;
        width: 136px;
        height: 102px;
        border-radius: 8px;
        background-color: var(--C-gray-200) !important;

        // 자동차 상품 예외 처리
        .car & {
          height: 136px;
        }
      }
      &_title {
        @include multiline-ellipsis(2);
        margin-top: 12px;
        color: var(--C-gray-700);
        line-height: 2rem;
        font-size: var(--font-size-14) !important;

        // 자동차 상품 예외 처리
        .car & {
          @include multiline-ellipsis(1);
          line-height: 2.2rem;
        }
      }
      &_detail {
        margin-top: 4px;
        color: var(--gray-500);
        line-height: 1.6rem;
        font-size: var(--font-size-11);
      }
      &_price {
        display: block;
        margin-top: 4px;
        font-weight: var(--font-weight-medium);
        font-size: var(--font-size-16);
        line-height: 2.4rem;
      }
    }
  }
  // 혜택
  &_tab {
    overflow-x: auto;
    padding: 10px 16px;
    white-space: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
    &_cell {
      display: inline-block;
      margin: 0 4px;
    }
    &_item {
      overflow: hidden;
      height: 40px;
      padding: 0 16px;
      border: 1px solid var(--C-gray-300);
      border-radius: 40px;
      font-size: var(--font-size-16);

      &.active {
        border-color: var(--C-primary-400);
        color: var(--C-primary-500);
        font-weight: var(--font-weight-medium);
      }
    }
  }
  // 랭킹
  &_rank {
    margin: 30px 0 16px;

    &_item {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 10px;
      padding: 8px 92px 8px 20px;

      &_ico {
        width: 48px;
        height: 48px;
        margin-right: 8px;
      }
      &_label {
        display: block;
        color: var(--text-basic-description);
        line-height: 2.4rem;
        font-size: var(--font-size-18);

        &.disabled {
          color: var(--text-basic-disabled);
        }
      }
      &_rank {
        display: block;
        margin-top: 6px;
        color: var(--text-basic-description);
        line-height: 1.8rem;
        font-size: var(--font-size-12);

        &.up,
        &.down {
          font-weight: var(--font-weight-medium);

          &::before {
            display: inline-block;
            content: '';
            width: 18px;
            height: 18px;
            background: no-repeat 0 50% / 100% 100%;
            vertical-align: top;
          }
        }
        &.up {
          color: var(--red-500);

          &::before {
            background-image: url("../../assets/images/ico_rank_up.svg");
          }
        }
        &.down {
          color: var(--tmobi-blue-400);

          &::before {
            background-image: url("../../assets/images/ico_rank_down.svg");
          }
        }
        &.disabled {
          color: var(--text-basic-caption);
        }
      }
      &_view {
        position: absolute !important;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        width: 72px !important;
        border-radius: 8px;
        background: var(--background-button-secondary);
        color: var(--text-button-secondary);
        line-height: 3.6rem;
        text-align: center !important;
        font-size: var(--font-size-14);
      }
    }
  }
  &_settings {
    &_btn {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 40px 0 -20px;
      text-decoration: underline;
      text-underline-offset: 4px;
      text-decoration-thickness: 1px;
      color: var(--text-basic-caption);
      font-size: var(--font-size-12);
    }
  }
}
