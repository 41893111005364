@charset "UTF-8";

@font-face {
  font-family: TMOBI;
  src: local(TMOBI300),
  url('../../assets/fonts/TMOBI300.woff') format("woff"),
  url('../../assets/fonts/TMOBI300.ttf') format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: TMOBI;
  src: local(TMOBI500),
  url('../../assets/fonts/TMOBI500.woff') format("woff"),
  url('../../assets/fonts/TMOBI500.ttf') format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: TMOBI;
  src: local(TMOBI500),
  url('../../assets/fonts/TMOBI500.woff') format("woff"),
  url('../../assets/fonts/TMOBI500.ttf') format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: TMOBI;
  src: local(TMOBI700),
  url('../../assets/fonts/TMOBI700.woff') format("woff"),
  url('../../assets/fonts/TMOBI700.ttf') format("truetype");
  font-weight: 800;
  font-display: swap;
}
