.form {
  // 토글 스위치
  &_switch {
    overflow: hidden;
    display: inline-flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    width: 32px;
    height: 20px;

    &.large {
      width: 44px;
      height: 28px;

      .form_switch_checkbox {
        height: 20px;
        border-radius: 20px;

        &:checked {
          &::before {
            transform: translateX(16px);
          }
        }
        &::before {
          width: 26px;
          height: 26px;
        }
      }
    }
    &_checkbox {
      appearance: none;
      content: '';
      display: block;
      width: 100%;
      height: 14px;
      border-radius: 7px;
      background: var(--C-gray-400);

      &:checked {
        background: var(--C-primary-500);

        &::before {
          transform: translateX(12px);
          border-color: var(--C-primary-500);
        }
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        border: 1px solid var(--C-gray-400);
        background: var(--C-white);
        border-radius: 50%;
        transform: translateX(0px);
        transition: transform 0.1s ease 0s;
        will-change: transform;
      }
    }
  }
}
