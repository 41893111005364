@charset "UTF-8";

/* Common */
html {
  font-weight: var(--font-weight-normal); /* 1 */
  line-height: var(--base-line-height); /* 2 */
  -ms-text-size-adjust: none; /* 3 */
  -webkit-text-size-adjust: none; /* 3 */
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  font-size: var(--base-font-size);
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
table,
th,
td,
form,
fieldset,
legend,
input,
textarea,
button,
select {
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
}
body,
input,
textarea,
select,
button,
table {
  color: var(--text-basic-default);
  font-size: var(--font-size-16);
  line-height: var(--base-line-height);
  font-family: $base-font-family;
  font-weight: $base-font-weight;
}
optgroup {
  font-weight: var(--font-weight-medium);
}
body {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: none;
  word-wrap: break-word;
}
html,
body {
  background: var(--C-white);
  height: var(--G-contents);
  min-height: var(--G-contents);
}
img,
fieldset {
  border: 0;
}
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
ul,
ol {
  list-style: none;
}
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
  text-decoration: none;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
a:active,
a:hover {
  outline-width: 0;
}

table {
  border-collapse: collapse;
  word-break: break-all;
}
caption {
  padding: 0 !important;
}
textarea {
  resize: none;
}
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}
button {
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
  text-transform: none;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
button,
html [type="button"], /* 1 */
[type="reset"],
[type="submit"] {
  -webkit-appearance: none; /* 2 */
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

select {
  /* 1 */
  text-transform: none;
  appearance: none;
  -webkit-appearance: none;
}

article,
aside,
details, /* 1 */
figcaption,
figure,
footer,
header,
main, /* 2 */
menu,
nav,
section,
summary {
  /* 1 */
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template, /* 1 */
[hidden] {
  display: none;
}

abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

b,
strong {
  font-weight: var(--font-weight-normal);
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

svg:not(:root) {
  overflow: hidden;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

figure {
  margin: 1em 40px;
}

legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  white-space: normal; /* 1 */
}

textarea {
  overflow: auto;
}

i,
cite,
em,
var,
address,
dfn {
  font-style: normal;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  appearance: none;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

::-webkit-input-placeholder {
  color: var(--C-gray-300);
  opacity: 1;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--C-gray-300);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--C-gray-300);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--C-gray-300);
}
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
