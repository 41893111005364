@charset "UTF-8";

.analysis {
  // 상단 운전점수
  &_summary {
    position: relative;
    text-align: center;

    &_tip {
      @include inBlind;
      content: '';
      position: absolute !important;
      top: -44px;
      left: 0;
      width: 28px;
      height: 28px;
      mask: url("../../assets/images/ico_question_24.svg") no-repeat 50% 50% / 100% 100%;
      background-color: var(--C-gray-500);
    }
    &_share {
      display: flex;
      align-items: center;
      position: absolute !important;
      top: -44px;
      right: 0;
      height: 32px;
      padding: 0 16px 0 12px;
      border-radius: 20px;
      background: var(--C-gray-200);
      color: var(--C-gray-700);
      line-height: 1.6rem;
      font-size: var(--font-size-11);

      &::before {
        content: '';
        width: 16px;
        height: 16px;
        margin-right: 2px;
        mask: url("../../assets/images/ico_share.svg") no-repeat 0 0 / 100% 100%;
        background-color: var(--C-gray-700);
      }
    }
    &_graph {
      position: relative;

      &_tit {
        @include position(40px, 0, null, 0);
        color: var(--C-gray-500);
        line-height: 2rem;
        font-size: var(--font-size-14);
      }
      &_score {
        @include position(60px, 0, null, 0);
        margin-top: 4px;
        line-height: 5.2rem;
        font-size: 4rem;
        font-weight: var(--font-weight-bold);

        .unit {
          font-size: var(--font-size-24);
          font-weight: var(--font-weight-medium);
          vertical-align: baseline;
        }
      }
      &_view {
        margin-bottom: -24px;
      }
      &_desc {
        margin-top: 24px;
        color: var(--text-basic-description);
        line-height: 2rem;
        font-size: var(--font-size-14);

        .point {
          color: var(--text-basic-default);
          font-weight: var(--font-weight-medium);
        }
      }
      &_link {
        width: auto !important;
        height: 32px;
        margin-top: 8px;
        padding: 0 12px;
        border: 1px solid var(--gray-300);
        border-radius: 4px;
        font-size: var(--font-size-14);
      }
    }
    &_benfit {
      min-height: 80px;
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      padding: 20px 16px;
      border-radius: 12px;
      background: var(--tmobi-blue-200);
      line-height: 2.4rem;

      &_title {
        color: var(--text-basic-accent);
        font-weight: var(--font-weight-medium);
      }

      &_sub {
        color: var(--text-basic-description);
        white-space: pre-wrap;
      }
    }
    &_none {
      position: relative;
      margin-top: 12px;
      text-align: center;

      &::before {
        @include set-bg(img_case_notice);
        display: inline-block;
        content: '';
        width: 64px;
        height: 64px;
      }
      &_tit {
        margin-top: 32px;
        color: var(--C-gray-700);
        line-height: 2.4rem;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-medium);
      }
      &_desc {
        margin-top: 8px;
        color: var(--C-gray-500);
        line-height: 2.4rem;
      }
      &_subtit {
        height: 36px;
        margin: 56px 0 32px;
        border-radius: 8px;
        background: var(--C-gray-100);
        color: var(--C-gray-400);
        line-height: 3.6rem;
        font-size: var(--font-size-16);
      }
      &_tip {
        @include inBlind;
        content: '';
        position: absolute !important;
        top: -28px;
        left: 0;
        width: 28px;
        height: 28px;
        mask: url("../../assets/images/ico_question_24.svg") no-repeat 50% 50% / 100% 100%;
        background-color: var(--C-gray-500);
      }
      .analysis_summary_graph_rank {
        &::after {
          display: none;
        }
        .point {
          margin-left: 14px;
        }
      }
    }
  }
  // 운전점수 라인 그래프
  &_score {
    &_tit {
      color: var(--C-gray-900);
      line-height: 2.8rem;
      font-weight: var(--font-weight-medium);
    }
    &_graph {
      position: relative;
      height: 153px;
      margin-top: 4px;

      &::after {
        @include position(null, 0, 28px, 0);
        content: '';
        border-bottom: 1px solid var(--C-gray-200);
      }
      &_draw {
        @include position(36px, 17px, null, 17px);
        z-index: 100;
        height: 80px;
      }
      &_rank {
        position: absolute;
        transform: translate(-50%, 0);

        &::before,
        &::after {
          position: absolute;
          transform: translate(-50%, -50%);
          content: '';
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background: var(--C-primary-500);
          opacity: 0.1;
        }
        &::before {
          animation: scale 2s infinite ease-out;

          @keyframes scale {
            0% {
              width: 26px;
              height: 26px;
            }
            50% {
              width: 0;
              height: 0;
            }
            100% {
              width: 26px;
              height: 26px;
            }
          }
        }
        &::after {
          width: 10px;
          height: 10px;
          opacity: 1;
        }
        & span {
          position: absolute;
          transform: translate(-50%, -56px);
          height: 32px;
          padding: 0 14px;
          border-radius: 4px;
          background: var(--C-gray-200);
          color: var(--C-gray-700);
          line-height: 3.2rem;
          font-size: 1.3rem;
          white-space: nowrap;

          &::after {
            @include position(100%, null, null, calc(50% - 12px / 2));
            content: '';
            width: 12px;
            height: 0;
            border-top: 6px solid var(--C-gray-200);
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            box-sizing: border-box;
          }
        }
      }
      &_axisx {
        @include position(null, 17px, 0, 17px);
        z-index: 10;
      }
      &_point {
        position: absolute;
        bottom: 0;
        color: var(--C-gray-700);
        line-height: 1.6rem;
        font-size: var(--font-size-12);
        font-weight: var(--font-weight-medium);
        white-space: nowrap;

        &.start {
          left: 0;
        }
        &.average {
          transform: translateX(-50%);
          color: var(--C-gray-500);
          font-weight: var(--font-weight-normal);

          &::before {
            @include position(-101px, null, 29px, 50%);
            content: '';
            border-left: 2px dashed var(--C-gray-300);
            transform: translateX(-50%);
          }
        }
        &.end {
          right: 0;
        }
      }
    }
  }
  // 우리동네 랭킹
  &_region {
    margin-top: 22px;

    &_map {
      overflow: hidden;
      position: relative;
      height: 224px;
      border-radius: 12px;

      &_marker {
        position: relative;
        z-index: 20;
        top: -15px;
      }
      &_info {
        @include position(-40px, null, null, 0);
        transform: translateX(calc(-50% + 20px));
        height: 40px;
        padding: 0 20px;
        border-radius: 24px;
        background: var(--tmobi-blue-500);
        color: var(--wb-white);
        line-height: 4rem;
        font-weight: var(--font-weight-medium);
        white-space: nowrap;
      }
      &_boundary {
        position: absolute;
        z-index: 5;
        width: 168px;
        height: 168px;
        border-radius: 50%;

        &::before,
        &::after {
          @include position(0, 0, 0, 0);
          content: '';
          margin: auto;
          border-radius: 50%;
          animation: 2.5s fadeInOut infinite;

          @keyframes fadeInOut {
            0% {
              opacity: 0.2;
            }
            50% {
              opacity: 0.5;
            }
            100% {
              opacity: 0.2;
            }
          }
        }
        &::before {
          width: 96px;
          height: 96px;
          background: rgba(68, 207, 226, 0.7);
        }
        &::after {
          width: 100%;
          height: 100%;
          background: rgba(68, 207, 226, 0.2);
          opacity: 0.5;
        }
      }
    }
    &_none {
      margin-top: 22px;
      text-align: center;

      &_img {
        width: 100%;
        max-width: 400px;
        height: auto;
      }
      &_link {
        margin-top: 20px;
        height: 56px;
        padding: 0 24px;
        border-radius: 8px;
        background: var(--C-primary-200);
        color: var(--C-primary-500);
        text-align: center !important;
        font-size: var(--font-size-18);
        font-weight: var(--font-weight-medium);
      }
    }
    &_guide {
      position: relative;
      margin-top: 20px;
      padding: 16px 16px 16px 46px;
      border-radius: 8px;
      background: var(--C-gray-100);
      word-break: keep-all;

      &::before {
        @include position(16px, null, null, 16px);
        content: '';
        width: 22px;
        height: 22px;
        mask: url('../../assets/images/ico_notice_solid.svg') no-repeat 0 0 / 100% 100%;
        background-color: var(--C-gray-400);
      }
      &_tit {
        color: var(--C-gray-700);
        line-height: 2.4rem;
        font-size: var(--font-size-16);
      }
      &_desc {
        margin-top: 4px;
        color: var(--C-gray-600);
        line-height: 1.8rem;
        font-size: var(--font-size-12);
      }
    }
  }
  // 차량 랭킹
  &_vehicle {
    &_expected {
      margin-top: 32px;
      color: var(--text-basic-description);
      line-height: 2.4rem;
      font-size: var(--font-size-18);
      word-break: keep-all;

      &_text {
        &:not(:first-of-type) {
          margin-top: 4px;
        }
        .up,
        .down {
          @include inBlind;
          position: relative;
          width: 24px;
          height: 24px;
          margin: 0 8px;
          border: 1px solid var(--C-gray-300);
          border-radius: 50%;
          vertical-align: top;

          &::before {
            @include position(0, 0, 0, 0);
            content: '';
            width: 16px;
            height: 16px;
            margin: auto;
            mask: no-repeat 0 0 / 100% 100%;
            background-color: var(--C-gray-700);
          }
        }
        .up::before {
          mask-image: url("../../assets/images/ico_arrow_up_bold.svg");
        }
        .down::before {
          mask-image: url("../../assets/images/ico_arrow_down_bold.svg");
        }
        .point {
          color: var(--text-basic-default);
          font-weight: var(--font-weight-medium);
        }
        .primary {
          color: var(--text-basic-accent);
          font-weight: var(--font-weight-medium);
        }
      }
    }
    &_notice {
      margin-top: 24px;
      padding: 16px;
      border-radius: 8px;
      background: var(--C-orange-200);

      &_title {
        position: relative;
        padding-left: 26px;
        color: var(--C-wb-transparent-700);
        line-height: 2.2rem;
        font-size: var(--font-size-14);

        &::before {
          @include position(0, null, null, 0);
          @include set-bg(ico_notice_solid);
          content: '';
          width: 22px;
          height: 22px;
        }
      }
      &_desc {
        margin-top: 4px;
        padding-left: 26px;
        color: var(--C-wb-transparent-600);
        line-height: 1.8rem;
        font-size: var(--font-size-12);
      }
    }
    &_registration {
      overflow: hidden;
      position: relative;
      height: 56px;
      margin-top: 36px;
      border-radius: 12px;
      background: var(--C-primary-200);
      color: var(--C-primary-500);
      text-align: center !important;
      font-weight: var(--font-weight-medium);
    }
  }
  // 감점 요인 분석
  &_violation {
    position: relative;

    &_desc {
      padding-top: 16px;
      color: var(--text-basic-caption);
      line-height: 2.4rem;

      &_point {
        color: var(--text-basic-default);
        font-weight: var(--font-weight-medium);
      }
    }
    &_chart {
      overflow: hidden;
      position: relative;
      width: 152px;
      height: 152px;
      margin: 28px auto;
      border-radius: 50%;
      background: var(--gray-200);

      &::before {
        @include position(0, 0, 0, 0);
        z-index: 20;
        content: '';
        width: 104px;
        height: 104px;
        margin: auto;
        background: var(--wb-white);
        border-radius: 50%;
      }
      &_circle {
        @include position(0, 0, 0, 0);
      }
      &_tit {
        @include position(50%, null, null, 50%);
        z-index: 30;
        transform: translate(-50%, -50%);
        color: var(--text-basic-description);
        line-height: 2.4rem;
        text-align: center;
      }
    }
    &_factors {
      display: flex;
      justify-content: center;
      gap: 0 20px;

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 72px;
        text-align: center;

        .label {
          display: flex;
          align-items: center;
          color: var(--gray-400);
          line-height: 2rem;
          font-size: var(--font-size-14);

          &::before {
            content: '';
            display: inline-block;
            overflow: hidden;
            width: 16px;
            height: 16px;
            margin-right: 4px;
            border-radius: 50%;
          }
          &.rapid_deceleration::before {
            background: var(--tmobi-brand-oil);
          }
          &.rapid_acceleration::before {
            background: var(--tmobi-brand-ev);
          }
          &.speeding::before {
            background: var(--tmobi-brand-benefit);
          }
        }
        .count {
          margin-top: 4px;
          color: var(--gray-600);
          line-height: 2.8rem;
          font-size: var(--font-size-20);
          font-weight: var(--font-weight-bold);
        }
      }
    }
    &_tip {
      margin-top: 24px;
      border-radius: 12px;
      background: var(--gray-50);

      &_tit {
        display: flex;
        align-items: center;
        position: relative;
        height: 56px;
        padding: 0 18px 0 16px;
        color: var(--text-basic-description);

        &::before {
          @include set-bg(bulb_1);
          content: '';
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .tmds-icon {
          @include position(0, 18px, 0);
          margin: auto 0;
        }
      }
      &_cont {
        margin-top: 5px;
        padding: 0 20px 32px;
        color: var(--text-basic-caption);
        line-height: 1.8rem;
        font-size: var(--font-size-12);

        .bullet {
          display: inline-block;
          padding-left: 14px;
          text-indent: -14px;
        }
        .guide {
          margin-top: 32px;
          text-align: center;

          & > button {
            display: inline-flex;
            align-items: center;
            gap: 0 2px;
            color: var(--C-gray-700);
            line-height: 2.2rem;
            font-size: var(--font-size-14);
          }
        }
      }
    }
    //&_btn {
    //  position: absolute !important;
    //  top: -24px;
    //  right: 0;
    //}
  }
  // 월별주행 및 점수
  &_monthly {
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    margin: 24px -20px -10px;
    height: 254px;

    &::-webkit-scrollbar {
      display: none;
    }

    &_chart {
      height: 254px;
      padding-top: 8px;

      &.align_center {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    &_canvas {
      padding: 0 48px;
    }
    &_list {
      position: absolute;
      top: 8px;
      left: 48px;
      bottom: 0;

      &_item {
        position: absolute;
        transform: translateX(-50%);
        width: 62px;
        height: 100%;
        color: var(--text-basic-caption);
        text-align: center;
        white-space: nowrap;

        &.active {
          .dot {
            border-color: var(--text-basic-accent);
          }
          .score {
            color: var(--text-basic-accent);
          }
          .month {
            bottom: 0;
            left: -4px;
            width: 70px;
            height: 76px;
            background: url("../../assets/images/box_focus_graph.png") no-repeat 0 100% / 100% 100%;
            color: var(--text-basic-default);
            font-weight: var(--font-weight-normal) !important;
          }
        }
        .dot {
          position: absolute;
          transform: translate(-50%, -50%);
          width: 8px;
          height: 8px;
          border: 4px solid var(--gray-200);
          border-radius: 50%;
          background: var(--wb-white);
        }
        .score {
          @include position(null, 0, 82px, 0);
          @include font-size-16-bold;
          width: 56px;
          margin: 0 auto;
          font-weight: var(--font-weight-medium);
        }
        .month {
          @include position(null, 0, 10px, 0);
          @include font-size-14;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 56px;
          height: 56px;
          margin: 0 auto;
          border-radius: 12px;
          background: var(--gray-100);
        }
      }
    }
  }
  // 거리 별 예상 점수
  &_expected {
    padding-top: 66px;

    &_gauge {
      position: relative;
      height: 98px;
      margin: 0 20px;

      &_bar {
        position: relative;
        height: 16px;
        border-radius: 8px;
        background: var(--gray-200);

        .on {
          @include position(0, null, 0, 0);
          border-radius: 8px;
          background: var(--tmobi-blue-500);
          animation: linear 2s;
        }
      }
      &_current {
        @include position(-6px, 0, null, 0, 20);
        margin: 0 8px;

        .on {
          position: absolute;
          top: 0;
          transform: translateX(-50%);
          width: 28px;
          height: 28px;
          border: 2px solid var(--tmobi-blue-500);
          box-sizing: border-box;
          border-radius: 50%;
          box-shadow: 0 4px 4px var(--black-alpha-300);
          background: var(--wb-white);
        }
        .ico {
          @include position(null, null, 100%, 50%);
          transform: translateX(-50%);
        }
      }
      &_list {
        @include position(0, 8px, null, 8px);
        height: 100%;
      }
      &_item {
        @include position(0, 0, 0, null);
        transform: translateX(-50%);
        width: 56px;

        &.active {
          .distance {
            bottom: 0;
            left: -7px;
            width: 70px;
            height: 76px;
            background: url("../../assets/images/box_focus_graph.png") no-repeat 0 100% / 100% 100%;
            color: var(--text-basic-default);
            font-weight: var(--font-weight-medium);
          }
          .dot {
            display: none;
          }
        }
        .dot {
          @include position(2px, 0, null, 0);
          width: 8px;
          height: 8px;
          border: 2px solid var(--gray-400);
          border-radius: 50%;
          margin: auto;
          background: var(--wb-white);

          &.on {
            border-color: var(--tmobi-blue-500);
          }
        }
        .distance {
          @include position(null, 0, 10px, 0);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          border-radius: 12px;
          background: var(--gray-100);
          color: var(--gray-500);
          line-height: 2rem;
          font-size: var(--font-size-14);
        }
        .unit {
          line-height: 1.6rem;
          font-size: var(--font-size-11);
        }
      }
      @for $i from 0 to 5 {
        &_item:nth-child(#{$i + 1}) {
          left: #{$i * 25%};
        }
      }
    }
    &_score {
      margin-top: 12px;
      padding: 16px;
      border-radius: 12px;
      background: var(--tmobi-blue-100);
      text-align: center;

      &_tit {
        color: var(--text-basic-caption);
        line-height: 2.4rem;
        word-break: keep-all;

        .point {
          color: var(--text-basic-default);
          font-weight: var(--font-weight-medium);
        }
      }
      &_value {
        color: var(--tmobi-blue-500);
        line-height: 4.2rem;
        font-size: 3.2rem;
        font-weight: var(--font-weight-bold);

        .unit {
          font-size: var(--font-size-16);
          font-weight: var(--font-weight-medium);
          vertical-align: baseline;
        }
      }
    }
  }
  // 공통 - 랭킹 박스
  &_rank {
    @include flex;
    @include flex-justify(space-between);
    @include flex-align(center);
    position: relative;
    min-height: 80px;
    margin-top: 22px;
    padding: 0 16px 0 48px;
    border-radius: 12px;
    background: var(--tmobi-blue-50);
    white-space: nowrap;

    &::before {
      @include set-bg(ico_score_trophy);
      @include position(0, null, 0, 16px);
      display: block;
      content: '';
      width: 24px;
      height: 24px;
      margin: auto;
    }
    &.none {
      justify-content: flex-start;

      .analysis_rank_info {
        position: static;
        transform: translateY(0);
        margin-left: 16px;

        &_desc {
          line-height: 2rem;
          text-align: left;
          font-size: var(--font-size-14);
          white-space: normal;
        }
      }
    }
    &_tit {
      line-height: 2.4rem;
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-18);
    }
    &_state {
      display: flex;
      margin-top: 2px;
      color: var(--text-basic-caption);
      line-height: 1.8rem;
      font-size: var(--font-size-12);

      &.up,
      &.down {
        font-weight: var(--font-weight-medium);

        &::before {
          display: inline-block;
          content: '';
          width: 18px;
          height: 18px;
          background: no-repeat 0 50% / 100% 100%;
          vertical-align: top;
        }
      }
      &.up {
        color: var(--red-500);

        &::before {
          background-image: url("../../assets/images/ico_rank_up.svg");
        }
      }
      &.down {
        color: var(--tmobi-blue-400);

        &::before {
          background-image: url("../../assets/images/ico_rank_down.svg");
        }
      }
    }
    &_info {
      @include position(50%, 16px);
      transform: translateY(-50%);

      &_desc {
        margin: 4px 0;
        color: var(--text-basic-caption);
        line-height: 1.8rem;
        text-align: right;
        font-size: var(--font-size-12);

        .point {
          color: var(--text-basic-description);
          font-weight: var(--font-weight-medium);
        }
      }
    }
  }
  // 공통 - 라운드박스형 정보
  &_report {
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    background: var(--C-gray-100);

    &.monthly {
      border: 1px solid var(--C-gray-200);
      background: none;
    }
    &_title {
      color: var(--C-gray-700);
      font-size: var(--font-size-16);
      line-height: 2.4rem;
    }
    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 2.4rem;

      &:not(:first-of-type) {
        margin-top: 20px;
      }
    }
    &_label {
      display: flex;
      align-items: center;
      color: var(--C-gray-500);
    }
    &_value {
      font-weight: var(--font-weight-medium);
    }
    &_detail_btn {
      margin-top: 20px;
      width: 100%;
      height: 52px;
      background-color: var(--C-gray-200);
      text-align: center !important;
      border-radius: 6px;

      &.disabled {
        background-color: var(--C-gray-100);
        color: var(--C-gray-400);
      }
    }
  }
  // 공통 - 팁
  &_tip {
    @include inBlind;
    position: absolute !important;
    top: 38px;
    right: 20px;
    width: 24px !important;
    height: 24px;
    mask: url('../../assets/images/ico_question.svg') no-repeat 0 0 / 100% 100%;
    background-color: var(--C-gray-600);
  }
}
