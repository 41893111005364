.tmds-icon {
  display: inline-block;
  background-size: 100% 100%;
  mask-size: 100% 100%;
  vertical-align: top;

  &.xl {
    width: 28px;
    height: 28px;
  }
  &.large {
    width: 24px;
    height: 24px;
  }
  &.medium {
    width: 20px;
    height: 20px;
  }
  &.small {
    width: 16px;
    height: 16px;
  }
  &.xs {
    width: 14px;
    height: 14px;
  }
  &.xxs {
    width: 12px;
    height: 12px;
  }
}
