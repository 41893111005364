@charset "UTF-8";

@import '../basics/mixins';

// 운전이력 리스트
.wrap {
  padding: 0 16px 40px;

  &.more {
    padding-bottom: 76px;
  }
  &.skeleton {
    padding: 0 20px 36px;
  }
}
.item {
  margin-top: 12px;

  &:first-of-type {
    margin-top: 0;
  }
  &_date {
    display: block;
    padding: 24px 0 12px 4px;
    color: var(--C-gray-800);
    line-height: 20px;
    font-size: var(--font-size-14);
  }
  &_info {
    padding: 12px 20px 24px;
    border: 1px solid var(--C-gray-200);
    border-radius: 12px;
    background: var(--C-white);

    &.simple {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      border: 0;
      border-radius: 0;
      background: none;

      .item_info_dest {
        flex: 1;
        font-weight: var(--font-weight-normal);
      }
      .item_info_detail {
        min-width: 64px;
        margin-left: 4px;
        color: var(--C-gray-500);
        line-height: 2rem;
        font-size: var(--font-size-14);
        white-space: nowrap;
      }
      .item_info_alert {
        margin-top: 0;
      }
    }
    &_dest {
      overflow: hidden;
      position: relative;
      padding: 8px 0;
      color: var(--C-gray-900);
      line-height: 2.2rem;
      font-weight: var(--font-weight-medium);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &_detail {
      color: var(--C-gray-500);
      line-height: 2rem;
      font-size: var(--font-size-14);

      span {
        color: var(--C-gray-300);
      }
    }
    &_alert {
      width: 100%;
      margin-top: 8px;

      &_msg {
        display: inline-block;
        padding: 0 4px;
        border-radius: 4px;
        background: var(--C-tmobi-green-200);
        color: var(--C-tmobi-green-500);
        line-height: 2rem;
        font-size: var(--font-size-12);

        &.warning {
          background: var(--C-red-200);
          color: var(--C-red-500);
        }
      }
    }
  }
}
.banner {
  position: relative;
  z-index: 100;
  margin: 12px 10px;

  button {
    text-align: center !important;
  }
  img {
    width: 100%;
    max-width: 300px;
  }
}
.none {
  position: relative;
  height: calc(100vh - 108px);
  box-sizing: border-box;

  &_msg {
    @include position(0, 0, 0, 0);
    @include flex;
    @include flex-direction(column);
    @include flex-justify(center);
    @include flex-align(center);
    color: var(--C-gray-700);
    line-height: 2.4rem;
    font-size: var(--font-size-18);
    font-weight: var(--font-weight-medium);
    text-align: center;

    &::before {
      @include set-bg(nothing_1);
      content: '';
      width: 64px;
      height: 64px;
      margin-bottom: 32px;
    }
  }
}
.faq {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  padding: 0 17px 0 20px;
  background: var(--C-gray-200);
  color: var(--C-gray-500);

  &::after {
    @include set-bg(ico_16_right);
    content: '';
    width: 12px;
    height: 14px;
  }
}
