@charset "UTF-8";

.partial_loading_indicator {
  @include position(0, 0, 0, 0);
  width: 40px;
  height: 40px;
  margin: auto;

  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    mask: url("../../assets/images/circular.svg") no-repeat 0 0 / 100% 100%;
    background-color: var(--C-gray-300);
  }
  &.infinite {
    top: auto;
    bottom: 20px;
    padding: 4px 0;
  }
}
